<template>
  <BaseDialog :dialogVisible.sync="dialog" :title="title">
    <div class="referenceSizeSettingFormComponent" v-loading="loading">
      <div class="header">
        <div class="label">原型名称：</div>
        <div class="name">
          {{ name }}
        </div>
        <!--<template v-if="Object.keys(baseData).length">
          <div class="label" style="margin-left: 15px">当前标准码：</div>
          <el-tag type="priamry" size="mini">
            {{ baseData.sizeName || '' }}
          </el-tag>
        </template>-->
      </div>
      
      <div class="table-wrapper">
        <CommonTable height="auto" :selection="false" :cols="cols" :infoData="data">
          <template #kl_docsSlot="{ scoped }">
            <span v-if="scoped.$fileName"> {{ scoped.$fileName }}</span>
            <ColorTextBtn v-else type="warning"> 缺少psd文件 </ColorTextBtn>
          </template>
          
          <template #kl_groupsSlot="{ scoped }">
            {{ scoped.$isSafe ? '有' : '无' }}
          </template>
          <template #isReferenceSize="{ scoped }">
            {{ scoped.$isReferenceSize ? '是' : '否' }}
          </template>
          <template #selectSlot="{ scoped }">
            <template v-if="scoped.$isReferenceSize">{{ scoped.sizeName }}</template>
            <avue-crud-select
              v-else
              v-model="scoped.row.referSizeId"
              label="参考尺码"
              :dic="baseData"
              :props="sizeProps"
              size="medium"
              style="width: 160px;"
              :clearable="false"
            ></avue-crud-select>
          </template>
          
          <slot v-if="step === 1">
            <el-table-column align="center" label="操作" width="280px">
              <template slot-scope="{ row }">
                <ColorTextBtn @click="delReferSize(row)" v-if="row.$isReferenceSize" type="primary">
                  取消设置
                </ColorTextBtn>
                <template v-else>
                  <ColorTextBtn v-if="row.$isSafe" type="primary" @click="addReferSize(row)">
                    设为参考尺码
                  </ColorTextBtn>
                  
                  <ColorTextBtn v-if="!row.$isSafe" type="danger"> 缺安全线，无法设置 </ColorTextBtn>
                </template>
              </template>
            </el-table-column>
          </slot>
        </CommonTable>
      </div>
    </div>
    
    <template #footer>
      <el-button class="text-small" @click="cancel">{{ stepOption[step].cancelText }}</el-button>
      <loadingBtn class="text-small" type="primary" @click="onsubmit">{{ stepOption[step].confirmText }}</loadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import { referenceSizeSettingCols } from '../../../cols'
import { commonFromMixin } from '@/mixins'
import { batchEditReferSize, batchRelationReferSize } from '@/api/product/protoSizeApi'
import { getPicName } from '@/utils'
import { deepClone, getDiffData } from '@/components/avue/utils/util'

export default {
  mixins: [commonFromMixin],
  props: {
    name: String,
    isAdd: {
      type: Boolean,
      default: true
    },
    cData: {
      type: Array,
      required: true
    },
    baseData: {
      type: Array,
      required: true
    },
    protoId: {
      required: true
    },
    getList: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      step: 1,
      stepOption: {
        1: {
          cancelText: '取消',
          confirmText: '下一步'
        },
        2: {
          cancelText: '返回',
          confirmText: '确定'
        }
      },
      sizeProps: {
        label: 'sizeName',
        value: 'id'
      },
      data: this.cData,
      loading: false
    }
  },
  computed: {
    fileName() {
      return (row) => {
        try {
          const path = row.psdPath || ''
          return path.slice(path.lastIndexOf('/') + 1)
          // return getPicName(row.psdPath)
        } catch (err) {
          return ''
        }
      }
    },
    isSafe() {
      return (row) => {
        return this.existSafeLine(row)
      }
    },
    isReferenceSize({ baseData }) {
      if (!baseData) return () => false
      const sizeNames = baseData.map(size => size.sizeName)
      return (row) => {
        return sizeNames.includes(row.sizeName)
      }
    },
    cols({ step = 1 }) {
      return referenceSizeSettingCols[step]
    }
  },
  watch: {
    cData: {
      handler(n) {
        let cData = deepClone(n)
  
        // 设为参考尺码的常规尺码idList
        this.oReferSizeIds = []
        cData.forEach(row => {
          let isReferenceSize = this.isReferenceSize(row)
          row.$isReferenceSize = isReferenceSize
          row.$fileName = this.fileName(row)
          row.$isSafe = this.isSafe(row)
          
          if (isReferenceSize) {
            this.oReferSizeIds.push(row.id)
          }
        })
  
        this.data = cData
      },
      immediate: true,
      deep: true
    },
    dialog: {
      handler(n) {
        if (!n && this.isChanged) {
          this.handleClose()
        }
      }
    }
  },
  created() {
    console.log('this.baseData', this.baseData)
    console.log('this.cData', this.cData)
  },
  methods: {
    existSafeLine(row) {
      try {
        if (!row.sizeLayerGroupList.length) {
          return false
        }
        return row.sizeLayerGroupList.every(({ sizeLayerGroupImageList }) => sizeLayerGroupImageList[0].safeLine)
      } catch (err) {
        return false
      }
    },
    async onsubmit() {
      if (this.step === 1) {
        await this.setReferSize()
      } else {
        await this.relationReferSize()
      }
    },
    async setReferSize() {
      let valid = await this.setValidate()
      if (!valid) return
  
      // 设为参考尺码的常规尺码idList
      let nReferSizeIds = []
      this.data.forEach(row => {
        if (row.$isReferenceSize) nReferSizeIds.push(row.id)
      })
      
      let batchDeleteSizeIdList = this.oReferSizeIds.filter(id => !nReferSizeIds.includes(id))
      let batchDeleteReferSizeIdList = []
      this.data.forEach(row => {
        if (batchDeleteSizeIdList.includes(row.id)) batchDeleteReferSizeIdList.push(row.referSizeId)
      })
      
      let postData = {
        // 常规尺码idList
        batchCreateReferSizeIdList: nReferSizeIds.filter(id => !this.oReferSizeIds.includes(id)),
        // 参考尺码idList
        batchDeleteReferSizeIdList,
        productPrototypeId: this.protoId
      }
      console.log(postData)
      
      if (postData.batchCreateReferSizeIdList.length || postData.batchDeleteReferSizeIdList.length) {
        let res = await awaitResolve(batchEditReferSize(postData))
        if (!res) return
        this.isChanged = true
      }
  
      await this.getList()
      this.step = 2
    },
    async setValidate() {
      if (this.data.every(size => !size.$isReferenceSize)) {
        this.$message.warning('请至少设置一个参考尺码')
        return
      }
      return true
    },
    async relationReferSize() {
      let valid = await this.relationValidate()
      if (!valid) return
  
      let nRelationReferSizes = {}
      this.data.forEach(row => {
        nRelationReferSizes[row.id] = row.referSizeId
      })
      let oRelationReferSizes = {}
      this.cData.forEach(row => {
        oRelationReferSizes[row.id] = row.referSizeId
      })
      let diffData = getDiffData(nRelationReferSizes, oRelationReferSizes)
      // console.log(diffData, nRelationReferSizes, oRelationReferSizes)
      if (diffData) {
        let relationReferSizeDTOList = []
        for (const sizeId in diffData) {
          relationReferSizeDTOList.push({
            sizeId,
            referSizeId: diffData[sizeId]
          })
        }
        
        let res = await awaitResolve(
          batchRelationReferSize(
            {
              relationReferSizeDTOList,
              productPrototypeId: this.protoId
            }
          )
        )
        if (!res) return await this.getList()
  
        this.isChanged = true
      }
      
      this.success()
    },
    async relationValidate() {
      console.log(this.data)
      let tempArr = this.data.filter(({ referSizeId, $isReferenceSize }) => !$isReferenceSize && !referSizeId)
      if (tempArr.length) {
        let errSizes = tempArr.reduce((str, { sizeName }) => str += `、${sizeName}`, '').slice(1)
        this.$message.warning(`未关联参考尺码的尺码: ${errSizes}`)
        return false
      }
      return true
    },
    async doFn() {
    
    },
    async doAdd(row, type) {
      let message = type === 'update' ? '此操作将更新标准尺码, 是否继续?' : '此操作将设置标准尺码, 是否继续?'
      if (!this.existSafeLine(row)) {
        return this.$message.error('缺少安全线，无法更新标准码，请重新上传psd文件。')
      }
      const bool = await this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return true
        })
        .catch(() => {
          this.$message.info('已取消操作')
          return false
        })
      if (!bool) return
      const { id: sizeId } = row
      this.loading = true
      try {
        const { code } = await add({
          sizeId,
          productPrototypeId: this.protoId
        })
        if ($SUC({ code })) {
          this.success()
        }
      } catch (err) {}
      this.loading = false
    },
    async doEdit(params) {
      this.loading = true
      try {
        const { code } = await bulkOff(params)
        if ($SUC({ code })) {
          this.success()
        }
      } catch (err) {}
      this.loading = false
    },
    success() {
      this.$message.success('操作成功')
      this.dialog = false
    },
    init() {
      this.$emit('updateData')
    },
    cancel() {
      if (this.step === 2) {
        this.step = 1
      } else {
        this.dialog = false
      }
    },
    
    handleClose() {
      this.sup_this && this.sup_this.init()
    },
    
    addReferSize(row) {
      row.$isReferenceSize = true
    },
    delReferSize(row) {
      row.$isReferenceSize = false
    }
  }
}
</script>
<style lang="scss" scoped>
.referenceSizeSettingFormComponent {
  display: flex;
  flex-direction: column;
  height: 100%;
  .header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
    align-items: center;
    .name {
    }
  }
  .table-wrapper {
    flex: 1;
    overflow: auto;
    .el-table {
      border: 1px solid #ebeef5;
      //border-right: none;
      ::v-deep {
        td {
          //border-right: none;
        }
      }
    }
  }
}
::v-deep {
  .el-dialog {
    .el-dialog__header {
      font-size: 14px;
    }
    .el-dialog__body {
      padding: 30px 25px;
    }
    .el-dialog__footer {
      padding: 0 22px 30px;
    }
  }
}
</style>
