var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDialog',{attrs:{"dialogVisible":_vm.dialog,"title":_vm.title},on:{"update:dialogVisible":function($event){_vm.dialog=$event},"update:dialog-visible":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-button',{staticClass:"text-small",on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.stepOption[_vm.step].cancelText))]),_c('loadingBtn',{staticClass:"text-small",attrs:{"type":"primary"},on:{"click":_vm.onsubmit}},[_vm._v(_vm._s(_vm.stepOption[_vm.step].confirmText))])]},proxy:true}])},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"referenceSizeSettingFormComponent"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"label"},[_vm._v("原型名称：")]),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]),_c('div',{staticClass:"table-wrapper"},[_c('CommonTable',{attrs:{"height":"auto","selection":false,"cols":_vm.cols,"infoData":_vm.data},scopedSlots:_vm._u([{key:"kl_docsSlot",fn:function(ref){
var scoped = ref.scoped;
return [(scoped.$fileName)?_c('span',[_vm._v(" "+_vm._s(scoped.$fileName))]):_c('ColorTextBtn',{attrs:{"type":"warning"}},[_vm._v(" 缺少psd文件 ")])]}},{key:"kl_groupsSlot",fn:function(ref){
var scoped = ref.scoped;
return [_vm._v(" "+_vm._s(scoped.$isSafe ? '有' : '无')+" ")]}},{key:"isReferenceSize",fn:function(ref){
var scoped = ref.scoped;
return [_vm._v(" "+_vm._s(scoped.$isReferenceSize ? '是' : '否')+" ")]}},{key:"selectSlot",fn:function(ref){
var scoped = ref.scoped;
return [(scoped.$isReferenceSize)?[_vm._v(_vm._s(scoped.sizeName))]:_c('avue-crud-select',{staticStyle:{"width":"160px"},attrs:{"label":"参考尺码","dic":_vm.baseData,"props":_vm.sizeProps,"size":"medium","clearable":false},model:{value:(scoped.row.referSizeId),callback:function ($$v) {_vm.$set(scoped.row, "referSizeId", $$v)},expression:"scoped.row.referSizeId"}})]}}])},[(_vm.step === 1)?_vm._t("default",[_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"280px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.$isReferenceSize)?_c('ColorTextBtn',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.delReferSize(row)}}},[_vm._v(" 取消设置 ")]):[(row.$isSafe)?_c('ColorTextBtn',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addReferSize(row)}}},[_vm._v(" 设为参考尺码 ")]):_vm._e(),(!row.$isSafe)?_c('ColorTextBtn',{attrs:{"type":"danger"}},[_vm._v(" 缺安全线，无法设置 ")]):_vm._e()]]}}],null,false,408145404)})]):_vm._e()],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }